<template>
  <main :class="{ active }">
    <div class="logo-area">
      <img src="@/assets/icons/logo.svg" class="logo" />
    </div>
    <RouterLink to="/" class="goToHome"><img src="@/assets/icons/goToHome.svg"></RouterLink>
    <AgeVerification v-if="!verified" @ok="verified = true" :loading="loading"/>
    <div class="main-container" v-show="verified">
      <transition mode="out-in" name="main">
        <Trivia />
      </transition>
<!--      <div id="mobile-controls-container"></div>-->
    </div>
    <!--    <div class="gradient" :class="{ active }" />-->
<!--    <transition>-->
      <div class="modal rotateToPortrait">
        <div class="modal-inner">
          <img src="@/assets/icons/rotate-to-portrait.svg" alt="rotate-device"/>
          <div class="title">Γύρισε τη συσκευή σου!</div>
          <div class="text">Γύρνα τη συσκευή κατακόρυφα για να λάβεις την πλήρη εμπειρία του quiz.</div>
        </div>
      </div>
<!--    </transition>-->
  </main>
  <BackgroundRays />
</template>

<script setup lang="ts">
import AgeVerification from "@/components/AgeVerification.vue";

import {onMounted, ref, watch} from 'vue';
import {useElementBounding} from "@vueuse/core";
import BackgroundRays from "@/components/BackgroundRays.vue";
import Trivia from "@/components/TriviaIluma/Trivia.vue";
import {useHead} from "@unhead/vue";
import {recordEvent} from "@/ts/helpers";

const verified = ref(true);
const active = ref(false);

const loading = ref(false);

const backToIqos = () => {
  presentation.value = true;
  activePresentationStep.value = 2;
}

watch(verified, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      active.value = true;
    }, 500);
  }
});

const circleInner = ref(null);

const bounding = useElementBounding(circleInner);

const dome = ref(null);

const setActiveConstellation = (constellation: string) => {
  // dome.value.setActive(constellation)
};

const reset = () => {
  // dome.value.reset();
}

const start = () => {
  // dome.value.start();
}

const opened = ref(false);
const elevation = ref(0);
const currentConstellation = ref("");

const presentation = ref(true);

const activePresentationStep = ref(0);

useHead({
  title: 'Digital Trivia - Activation Arena'
});

onMounted( () => {
  recordEvent('game-load', 1)
});
</script>

<style lang="scss" scoped>
main {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
  //padding: 0 24px;
}

.main-container {
  width: 100%;
  //height: 100%;
  display: flex;
  flex-flow: column;
  z-index: 10;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100svh;

  .spacer {
    height: 25%;
    flex: 1 1 auto;
  }
}

.circle-parent {
  //width: min( 80vw, 80vh );
  width: calc(100vh - 160px);

  @media screen and (orientation: portrait) {
    width: 520px;
    max-width: calc(100vh - 200px);
  }

}

#mobile-controls-container {
  position: relative;
  @media screen and (orientation: portrait) {
    height: 116px;
    //background-color: #f00;
  }
}

.circle {
  position: relative;
  width: 100%;
  padding-top: 100%;
  //background: #000;
  //border-radius: 50%;

  /*&::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    border: 0.6px solid #41557C;
    box-shadow: -4px 0px 12px 0px rgba(211, 180, 70, 0.10), 4px -4px 60px 4px rgba(229, 190, 116, 0.35), 4px -4px 12px 0px rgba(228, 225, 133, 0.10);
    position: absolute;
    opacity: 0;
    transition: 1.2s ease-out;
  }*/

  /*&.active {
    &::before {
      opacity: 1;
    }
  }*/

  .circle__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

/*.gradient {
  position: fixed;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 0;
  background: #000011 linear-gradient(317deg, #000102 19.28%, #071C28 38.67%, #07232C 41.16%, #010311 53.93%, #000 70.79%);
  background-blend-mode: exclusion;
  //background-color: #000011;
  //background-color: #f00;
  opacity: 0;
  transition: 1.2s ease-out;

  &.active {
    opacity: 1;
  }
}*/

.logo {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
}

.dome-parent {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  pointer-events: none;
  //opacity: .8;
}

#mobile-controls-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 14px;
  max-width: 100vw;
}

.rotateToPortrait {
  @media screen and (orientation: portrait), screen and (min-height: 500px) {
    display: none;
  }

  .modal-inner > * + * {
    margin-top: 22px;
  }
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

.logo-area {
  position: fixed;
  top: 20px;
  width: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  z-index: 10;
  left: 0;

  @media screen and (min-height: 780px) {
    top: 30px;
  }
}
.logo {
  height: 26px;
}
</style>
