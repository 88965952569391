<template>
  <div class="intro" :class="{ active }">
    <div class="intro-inner">
      <img src="@/assets/icons/trivia/intro-icon.svg" />
<!--      <div class="subtitle">Παρέλαβε το δώρο σου από τον advisor.</div>-->
      <div class="title">
        Σ' ευχαριστούμε πολύ <br>
        για τη συμμετοχή σου!
      </div>
      <Button @click="playAgain">
        <span>ΠΑΙΞΕ ΞΑΝΑ</span>
        <img src="@/assets/icons/trivia/play-again.svg" />
      </Button>
    </div>
    <!--    <img src="@/assets/icons/quiz-intro-bg.svg" class="intro-bg" />-->
  </div>
</template>

<script setup lang="ts">
import {ref} from 'vue';
import Button from "@/components/Button.vue";
import TriviaEllipse from "@/components/TriviaIluma/TriviaEllipse.vue";

const emit = defineEmits(['playAgain']);
const props = defineProps(['active']);

const playAgain = () => {
  emit('playAgain');

  try {
    window.gtag('event', 'quiz-playAgain');
  } catch ( e ) { console.error( e ) }
}

</script>

<style scoped lang="scss">
.intro {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //padding-bottom: 39px;
  //background: #f00;
  position: relative;

  /*&::before {
    border: 1px solid rgba(36, 34, 43, 0.10);

    opacity: 0.5;
    background: rgba(43, 40, 50, 0.20);

    box-shadow: 2px -2px 2px 2px rgba(5, 254, 255, 0.50), 0px -4px 20px 0px rgba(0, 210, 211, 0.06), 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset;
    border-radius: 50%;
    width: 520px;
    height: 520px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-220deg);
    transition: transform 2.6s ease-out;
  }

  &.active::before {
    transform: translate3d(-50%, -50%, 0);
  }*/
}

.intro-inner {
  display: flex;
  flex-flow: column;
  gap: 35px;
  align-items: center;
  justify-content: center;
  width: 520px;
  max-width: 100vw;
  z-index: 10;
  padding: 0 24px;
  @media screen and (min-width: 1024px) {
    padding: 48px;
  }
}

.title {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  font-size: 20px;
  @media screen and (min-height: 780px) {
    font-size: 24px;
  }
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 57.6px */
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.subtitle {
  color: var(--iqos-white, #FFFDFB);
  text-align: center;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  @media screen and (min-height: 780px) {
    font-size: 24px;
  }
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}


button {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;

  span {
    display: block;
    line-height: 1;
  }

  img {
    margin: 0;
    display: block;
  }
}

.intro-bg {
  position: fixed;
  bottom: 0;
  left: 0;
}

.circle {
  width: 478px;
  height: 43px;

  border-radius: 478px;
  border: 4px solid rgba(1, 168, 169, 0.00);

  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 210, 211, 0.40) 0%, rgba(119, 210, 211, 0.00) 100%), linear-gradient(180deg, rgba(39, 68, 73, 0.01) 0%, rgba(57, 98, 106, 0.20) 100%);

  box-shadow: 0px 2px 20px 0px rgba(128, 232, 233, 0.05) inset, 0px 4px 6px 1px rgba(40, 116, 132, 0.40), 0px -4px 20px 0px rgba(0, 210, 211, 0.06);
}
</style>
