<template>
  <div class="trivia-questions">
    <div class="trivia-questions__inner">
      <div class="category-icon">
        <img src="@/assets/icons/trivia-iluma/categories/flex-puff.png" v-if="category === 2"/>
        <img src="@/assets/icons/trivia-iluma/categories/pause.png" v-else-if="category === 1"/>
        <img src="@/assets/icons/trivia-iluma/categories/touchscreen.png" v-else-if="category === 0"/>
      </div>
      <div class="category-title">
        <img src="@/assets/icons/trivia-iluma/categories_titles/flex-puff.svg" v-if="category === 2"/>
        <img src="@/assets/icons/trivia-iluma/categories_titles/pause.svg" v-else-if="category === 1"/>
        <img src="@/assets/icons/trivia-iluma/categories_titles/touchscreen.svg" v-else-if="category === 0"/>
      </div>

      <transition mode="out-in" name="main">
        <TriviaQuestion
            :question="activeQuestionData"
            @setActiveAnswer="setActiveAnswer"
            @setTextValue="setTextValue"
            :key="activeQuestion"
        />
      </transition>

      <TriviaPagination :activeQuestion="activeQuestion" :length="activeCategoryData?.questions?.length"/>
      <div
          class="previous"
          v-if="activeQuestion > -1"
          :class="{ active: activeQuestion > 0 }"
          @click="prev"
      >
        <img src="@/assets/icons/previous.svg"/>
        <span>PREVIOUS</span>
      </div>
      <div
          class="next"
          v-if="activeQuestion > -1"
          :class="{ active: nextActive }"
          @click="next"
      >
        <img src="@/assets/icons/next.svg"/>
        <span>NEXT</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, inject} from 'vue';

import TriviaQuestion from "@/components/TriviaIluma/TriviaQuestion.vue";
import TriviaPagination from "@/components/TriviaIluma/TriviaPagination.vue";
import {recordAnswer} from "@/ts/helpers";

const emit = defineEmits(['next']);

// const props = defineProps(['category']);

const {data, category} = inject('trivia', {data: ref([]), category: ref(0)});


const activeCategoryData = computed(() => {
  return data.value[category.value] || {};
});

const activeQuestion = ref(0);

const activeQuestionData = computed(() => {
  return activeCategoryData.value?.questions?.[activeQuestion.value] || {};
});

const setActiveAnswer = (answer: number) => {
  data.value[category.value].questions[activeQuestion.value].activeAnswer = answer;
};

const setTextValue = (answer: string) => {
  data.value[category.value].questions[activeQuestion.value].value = answer;
};

const prev = () => {
  if (activeQuestion.value > 0) {
    activeQuestion.value--;
  }
};

const next = () => {

  if (activeQuestionData.value.freetext) {
    if (activeQuestionData.value.value === "") {
      return;
    }

    recordAnswer(
        4,
        activeQuestionData.value.questionId,
        activeQuestionData.value.value
    );
  } else {
    if (activeQuestionData.value.activeAnswer === -1) {
      return;
    }

    recordAnswer(
        4,
        activeQuestionData.value.questionId,
        activeQuestionData.value.activeAnswer,
        activeQuestionData.value.activeAnswer === activeQuestionData.value.correctAnswer
    );
  }



  if (activeQuestion.value < activeCategoryData.value.questions.length - 1) {
    activeQuestion.value++;
  } else {
    emit('next');
  }
};

const nextActive = computed(() => {
  if (activeQuestionData.value.freetext) {
    return activeQuestionData.value.value !== '';
  } else {
    return activeQuestionData.value.activeAnswer > -1
  }
});

</script>

<style scoped lang="scss">
.trivia-questions {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  padding: 0 24px;
}

.trivia-questions__inner {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 380px;
  gap: min(24px, 2vh);
  max-width: 100%;
}

.previous, .next, .play-again {
  position: absolute;
  top: 50%;
  z-index: 20;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transition: opacity .2s ease-out;
  width: 80px;
  opacity: .2;
  text-align: center;
  color: #F7F7F7;

  &.active, &.play-again {
    opacity: 1;
  }

  span {
    margin-top: 6px;
    font-size: 16px;
  }

  @media screen and (orientation: portrait) {
    position: fixed;
    bottom: 24px;
    top: unset;

    &.next {
      left: unset;
      right: 24px;
    }

    &.previous {
      right: unset;
      left: 24px;
    }
    //position: static;
    transform: none !important;
  }

}

.play-again {
  @media screen and (orientation: portrait) {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
  }
}

.previous {
  right: calc(50vw + 50vh + 80px);
  transform: translate3d(50%, -50%, 0);
}

.previous-next-spacer {
  flex: 1 1 auto;
}

.next, .play-again {
  left: calc(50vw + 50vh + 80px);
  transform: translate3d(-50%, -50%, 0);
}

.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}

.category-icon {
  img {
    display: block;
    margin-bottom: -10px;
  }
}

.category-title {
  img {
    display: block;
  }
}

:global(.main-container) {
  @media screen and (orientation: portrait) {
    padding-bottom: 35px;
  }

  @media screen and (min-height: 650px) and (orientation: portrait) {
    padding-bottom: 0;
  }
}

</style>
