<template>
  <div class="trivia">
    <transition mode="out-in" name="main">
      <TriviaIntro @start="state=1" v-if="state===0"/>
      <TriviaDiceDrop @next="startOnCategory" v-else-if="state===1"/>
      <TriviaQuestions
          v-else-if="state===2"
          :category="category"
          :key="category"
          @next="questionsFinished"
      />
      <TriviaCongratulations @next="state=4" v-else-if="state===3"/>
      <TriviaFinished @play-again="playAgain" v-else-if="state===4"/>
    </transition>
    <transition mode="out-in" name="main">
      <TriviaEllipse v-if="state!==2" />
      <div v-else />
    </transition>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, provide} from 'vue';
import TriviaIntro from "@/components/TriviaIluma/TriviaIntro.vue";
import TriviaDiceDrop from "@/components/TriviaIluma/TriviaDiceDrop.vue";
import TriviaQuestions from "@/components/TriviaIluma/TriviaQuestions.vue";
import TriviaCongratulations from "@/components/TriviaIluma/TriviaCongratulations.vue";
import TriviaFinished from "@/components/TriviaIluma/TriviaFinished.vue";
import TriviaEllipse from "@/components/TriviaIluma/TriviaEllipse.vue";

const state = ref(0);

const category = ref(-1);

const startOnCategory = (cat) => {
  state.value = 2;
  category.value = cat;
};

const data = ref([
  {
    //Touch Screen
    questions: [
      {
        question: "Στην Οθόνη Αφής του IQOS ILUMA i PRIME, μπορείς να δεις:",
        answers: [
          "Πόσες εμπειρίες σου έχουν απομείνει",
          "Τον υπολειπόμενο χρόνο εμπειρίας σου",
          "Όλα τα παραπάνω"
        ],
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 1,
      },
      {
        question: "Ποιο στοιχείο της Οθόνης Αφής υποδεικνύει την κατάσταση προθέρμανσης του IQOS ILUMA i PRIME;",
        answers: [
          "Ο φωτεινός δακτύλιος",
          "Οι κάθετες γραμμές",
          "Το χρώμα της οθόνης"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 0,
      },
    ]
  },
  {
    //Pause
    questions: [
      {
        question: "Όταν ενεργοποιείς τη Λειτουργία Παύσης του IQOS ILUMA i PRIME, μπορείς να κάνεις διάλειμμα από την εμπειρία σου...",
        answers: [
          "για 30 δευτερόλεπτα",
          "μέχρι 3 λεπτά",
          "μέχρι 8 λεπτά"
        ],
        correctAnswer: 2,
        activeAnswer: -1,
        questionId: 3,
      },
      {
        question: "Για να ενεργοποιήσεις τη Λειτουργία Παύσης του IQOS ILUMA i PRIME, ποια κίνηση πρέπει να κάνεις στην Οθόνη Αφής;",
        answers: [
          "Να σύρεις προς τα αριστερά",
          "Να σύρεις προς τα κάτω",
          "Να σύρεις προς τα δεξιά"
        ],
        correctAnswer: 1,
        activeAnswer: -1,
        questionId: 2,
      },
    ]
  },
  {
    //Flex Puff
    questions: [
      {
        question: "Με το Flex Puff του IQOS ILUMA i PRIME, πόσες επιπλέον εισπνοές μπορείς να απολαύσεις;",
        answers: [
          "Έως 4",
          "Έως 14",
          "Έως 24"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 4,
      },
      {
        question: "Με το Flex Battery του IQOS ILUMA i PRIME, μέχρι πόσες συνεχόμενες χρήσεις μπορείς να κάνεις;",
        answers: [
          "Έως 3",
          "Έως 13",
          "Έως 23"
        ],
        correctAnswer: 0,
        activeAnswer: -1,
        questionId: 5,
      },
    ]
  },
]);

const questionsFinished = () => {
  state.value = 3;

  setTimeout(() => {
    if (state.value === 3) {
      state.value = 4;
    }
  }, 5000);
};

const playAgain = () => {
  data.value.forEach((category) => {
    category.questions.forEach((question) => {
      if (question.freetext) {
        question.value = "";
      } else {
        question.activeAnswer = -1;
      }
    });
  });

  state.value = 0;
};

provide('trivia', {
  data,
  category
});
</script>

<style scoped lang="scss">
.main-enter-active,
.main-leave-active {
  transition: opacity 0.8s ease;
}

.main-enter-from,
.main-leave-to {
  opacity: 0;
}
</style>
